
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/index.vue?macro=true";
import { default as indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta } from "/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue?macro=true";
export default [
  {
    name: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta?.name ?? "index",
    path: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta?.path ?? "/",
    props: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta?.props ?? false,
    meta: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta || {},
    alias: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta?.alias || [],
    redirect: index0G6zSrrdTLUtDVqq8_45jaXvwURdYSwH2aq5Vli6KfDjcMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/index.vue")
  },
  {
    name: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta?.name ?? "lang",
    path: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta?.path ?? "/:lang?",
    props: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta?.props ?? false,
    meta: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta || {},
    alias: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta?.alias || [],
    redirect: indexMe5UlO9i6w4bPCjWxNW9j_hxReAS3p7fqzQb0dB8fbwMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta?.name ?? "lang-voucher-start",
    path: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta?.path ?? "/:lang?/voucher/start",
    props: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta?.props ?? false,
    meta: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta || {},
    alias: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta?.alias || [],
    redirect: startZ_45XTRQ_45odts75XI6U7spD9VefytFILnbeWDG_45VB44q4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta?.name ?? "lang-purchase-start",
    path: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta?.path ?? "/:lang?/purchase/start",
    props: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta?.props ?? false,
    meta: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta || {},
    alias: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta?.alias || [],
    redirect: startV81DhVkiMU2pe6C5SyUaRTgs1ojrbE6Llufz_5wnU4QMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta?.name ?? "lang-service-booking-checkout",
    path: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta?.props ?? false,
    meta: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta || {},
    alias: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta?.alias || [],
    redirect: checkouts3RJ65di688Rm8IsGxrXaRSJ6d0VP_454TkQ9GsPaSWgsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta?.props ?? false,
    meta: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta || {},
    alias: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta?.alias || [],
    redirect: koVmRidW_OvJZAkqhon0GYu2ZO4ZAcEbgTMuuKs6_45CiBYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta?.props ?? false,
    meta: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta || {},
    alias: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta?.alias || [],
    redirect: confirmationzTdrZL1J88YEI6Pvpe489AudFThkKphPgo3SZ7_45R_58Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta?.props ?? false,
    meta: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta || {},
    alias: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta?.alias || [],
    redirect: confirmationLeAiHIilM2oe5RNRgEPm4gmnotHDzUQ97vNkKpdSg_45UMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta?.props ?? false,
    meta: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta || {},
    alias: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta?.alias || [],
    redirect: buyerQM5kLI82MK4y2TbN0d3hCDvn3wglzDKT3vHdHRKdb5wMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta?.props ?? false,
    meta: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta || {},
    alias: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta?.alias || [],
    redirect: buyerI2z3TQJljLfp7e3hb9TNmlV_OpHJOthK9PT2ZM3jO7YMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta?.props ?? false,
    meta: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta || {},
    alias: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta?.alias || [],
    redirect: paymentEL5_OpG2YrfEalgoVCGor_gkFySRVGCi6bAeIbYZJBsMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta?.props ?? false,
    meta: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta || {},
    alias: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta?.alias || [],
    redirect: challenge07eNHz11_98_BkzSEhgv62ODsnAETK7ddy2OFUDGszUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta?.props ?? false,
    meta: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta || {},
    alias: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta?.alias || [],
    redirect: paymentJR6uihOu6hhkuxpOmRIUSrcIYdTsnJZmWLi3tqs_vdQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 0",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/a-coruna/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/a-coruna/carnota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/a-coruna/ferrol/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 5",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 6",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 7",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 8",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 9",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 10",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 11",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 12",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 13",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 14",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 15",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 16",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 17",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 18",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 19",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 20",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 21",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 22",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 23",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 24",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 25",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 26",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 27",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 28",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 29",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 30",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 31",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 32",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 33",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 34",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 35",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 36",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 37",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 38",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 39",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 40",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 41",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 42",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 43",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 44",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 45",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 46",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/castellon/montanejos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 47",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 48",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 49",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 50",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 51",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 52",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 53",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 54",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 55",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 56",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 57",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 58",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 59",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 60",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 61",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 62",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 63",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 64",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 65",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 66",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 67",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 68",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 69",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 70",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 71",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 72",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 73",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 74",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 75",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 76",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 77",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 78",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 79",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 80",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 81",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 82",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 83",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 84",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 85",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 86",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 87",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 88",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 89",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 90",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 91",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 92",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 93",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 94",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 95",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 96",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 97",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 98",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 99",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 100",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 101",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 102",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 103",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 104",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 105",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 106",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 107",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 108",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 109",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 110",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 111",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 112",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 113",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 114",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banheira-de-hidromassagem/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 115",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 116",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 117",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 118",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 119",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 120",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 121",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 122",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 123",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 124",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 125",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 126",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 127",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 128",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 129",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 130",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 131",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 132",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 133",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 134",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 135",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 136",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 137",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 138",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 139",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 140",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 141",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 142",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 143",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 144",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 145",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 146",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 147",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 148",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 149",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 150",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 151",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 152",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 153",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 154",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 155",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 156",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 157",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 158",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 159",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 160",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 161",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 162",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 163",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 164",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 165",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 166",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 167",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 168",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 169",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 170",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 171",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 172",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 173",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 174",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 175",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 176",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 177",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 178",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 179",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/banho-turco-hammam/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 180",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 181",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 182",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/berga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 183",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 184",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 185",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 186",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 187",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 188",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 189",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 190",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 191",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 192",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 193",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 194",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 195",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 196",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 197",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/grazalema/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 198",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 199",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 200",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 201",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 202",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 203",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 204",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 205",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 206",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 207",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 208",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 209",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/castellon/montanejos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 210",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 211",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 212",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 213",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 214",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 215",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 216",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/a-coruna/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 217",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/a-coruna/carnota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 218",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/a-coruna/ferrol/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 219",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 220",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 221",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 222",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 223",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 224",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 225",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 226",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 227",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 228",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 229",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 230",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 231",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 232",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 233",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 234",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 235",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 236",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 237",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 238",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 239",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 240",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 241",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 242",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 243",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 244",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 245",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/berga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 246",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 247",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 248",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 249",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 250",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 251",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 252",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 253",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 254",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 255",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 256",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 257",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 258",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 259",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 260",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/grazalema/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 261",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 262",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 263",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 264",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 265",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 266",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 267",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 268",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 269",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 270",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 271",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 272",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/castellon/montanejos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 273",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 274",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 275",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 276",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 277",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 278",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 279",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 280",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 281",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cordoba/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 282",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 283",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 284",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 285",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 286",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 287",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 288",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 289",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 290",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 291",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 292",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 293",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 294",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 295",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 296",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 297",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 298",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 299",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/faro/monte-gordo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 300",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 301",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 302",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 303",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 304",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 305",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 306",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/caldes-de-malavella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 307",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/castello-dempuries/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 308",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 309",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 310",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 311",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 312",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/girona/toses/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 313",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 314",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/agaete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 315",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 316",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 317",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 318",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 319",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 320",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 321",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 322",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 323",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/lanjaron/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 324",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 325",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 326",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 327",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 328",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 329",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 330",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 331",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 332",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 333",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 334",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 335",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 336",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 337",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 338",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/huesca/monzon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 339",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 340",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/jaen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 341",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/jaen/ubeda/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 342",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 343",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 344",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 345",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 346",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 347",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 348",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 349",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 350",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 351",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 352",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 353",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 354",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 355",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 356",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 357",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 358",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 359",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 360",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 361",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 362",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 363",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 364",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 365",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 366",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 367",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 368",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 369",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/benalmadena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 370",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 371",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 372",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 373",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 374",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 375",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 376",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 377",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 378",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 379",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 380",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/felanitx/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 381",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 382",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/muro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 383",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 384",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 385",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 386",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 387",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 388",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/murcia/archena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 389",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/murcia/la-manga-del-mar-menor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 390",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 391",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 392",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 393",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 394",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 395",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 396",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/paroquia-de-escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 397",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 398",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 399",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 400",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pontevedra/tui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 401",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 402",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 403",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 404",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 405",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 406",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/salamanca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 407",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/salamanca/bermellar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 408",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 409",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 410",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 411",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 412",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 413",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 414",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 415",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 416",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 417",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 418",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 419",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 420",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tarragona/torroja-del-priorat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 421",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 422",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 423",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 424",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 425",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/playa-de-las-americas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 426",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 427",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 428",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 429",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 430",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 431",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 432",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 433",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 434",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 435",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 436",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 437",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 438",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 439",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 440",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 441",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 442",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 443",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 444",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 445",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 446",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 447",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/circuito-termico/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 448",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 449",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 450",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 451",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 452",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cordoba/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 453",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 454",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 455",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 456",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 457",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 458",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 459",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 460",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 461",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 462",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 463",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 464",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 465",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 466",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 467",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 468",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 469",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 470",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 471",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 472",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 473",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 474",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 475",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 476",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 477",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 478",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 479",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 480",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 481",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 482",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 483",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 484",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 485",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 486",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 487",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 488",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 489",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 490",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 491",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 492",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 493",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 494",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 495",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 496",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 497",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 498",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 499",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 500",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 501",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/duche-terapeutico/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 502",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 503",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 504",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 505",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 506",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 507",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 508",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 509",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 510",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/faro/monte-gordo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 511",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 512",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 513",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 514",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 515",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 516",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 517",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/caldes-de-malavella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 518",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/castello-dempuries/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 519",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 520",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 521",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 522",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 523",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/girona/toses/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 524",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 525",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/agaete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 526",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 527",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 528",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 529",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 530",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 531",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 532",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 533",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 534",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/lanjaron/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 535",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 536",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 537",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 538",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 539",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 540",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 541",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 542",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 543",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 544",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 545",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 546",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 547",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 548",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 549",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 550",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 551",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/huesca/monzon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 552",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 553",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/jaen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 554",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/jaen/ubeda/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 555",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 556",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 557",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 558",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 559",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 560",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 561",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 562",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 563",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 564",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 565",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 566",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 567",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 568",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 569",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 570",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 571",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 572",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 573",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 574",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 575",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 576",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 577",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 578",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 579",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 580",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 581",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 582",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/benalmadena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 583",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 584",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 585",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 586",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 587",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 588",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 589",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 590",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 591",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 592",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 593",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/felanitx/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 594",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 595",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/muro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 596",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 597",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 598",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 599",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 600",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 601",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/murcia/archena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 602",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/murcia/la-manga-del-mar-menor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 603",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 604",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 605",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 606",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 607",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 608",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 609",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/paroquia-de-escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 610",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 611",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 612",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 613",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pontevedra/tui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 614",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 615",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 616",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 617",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 618",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 619",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/salamanca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 620",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/salamanca/bermellar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 621",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 622",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 623",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 624",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 625",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 626",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 627",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 628",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 629",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 630",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 631",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 632",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 633",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 634",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 635",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 636",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 637",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 638",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 639",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 640",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 641",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 642",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 643",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 644",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 645",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 646",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 647",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 648",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 649",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 650",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 651",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 652",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 653",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 654",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 655",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 656",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 657",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 658",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 659",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 660",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 661",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 662",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 663",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 664",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 665",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 666",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 667",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 668",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 669",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 670",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 671",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 672",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 673",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sauna-finlandesa/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 674",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 675",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 676",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 677",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 678",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 679",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 680",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 681",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 682",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 683",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 684",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 685",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 686",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 687",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 688",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 689",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 690",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/talassoterapia/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 691",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 692",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 693",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 694",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 695",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tarragona/torroja-del-priorat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 696",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 697",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 698",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 699",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 700",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/playa-de-las-americas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 701",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 702",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 703",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 704",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 705",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 706",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 707",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 708",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 709",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 710",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 711",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 712",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 713",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 714",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 715",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 716",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 717",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 718",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 719",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 720",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 721",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 722",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/hidroterapia/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 723",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 724",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 725",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/a-coruna/carnota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 726",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 727",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 728",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 729",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 730",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 731",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 732",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 733",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 734",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 735",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 736",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 737",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 738",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 739",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 740",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 741",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 742",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 743",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 744",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 745",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 746",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 747",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 748",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 749",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 750",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 751",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 752",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/berga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 753",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 754",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 755",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 756",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 757",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 758",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 759",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 760",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 761",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 762",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 763",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 764",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 765",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 766",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 767",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/grazalema/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 768",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 769",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 770",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 771",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 772",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 773",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 774",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 775",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 776",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 777",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 778",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 779",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 780",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 781",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 782",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 783",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 784",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 785",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 786",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 787",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 788",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 789",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 790",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 791",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 792",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 793",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 794",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 795",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 796",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 797",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 798",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 799",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 800",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 801",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 802",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 803",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 804",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/faro/monte-gordo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 805",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 806",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 807",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 808",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 809",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 810",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 811",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/caldes-de-malavella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 812",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/castello-dempuries/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 813",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 814",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 815",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 816",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 817",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/girona/toses/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 818",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 819",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/agaete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 820",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 821",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 822",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 823",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 824",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 825",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 826",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 827",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 828",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 829",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 830",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 831",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 832",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 833",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guadalajara/abanades/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 834",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 835",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 836",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 837",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 838",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 839",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 840",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 841",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 842",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 843",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 844",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 845",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 846",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 847",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/jaen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 848",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/jaen/ubeda/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 849",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 850",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 851",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 852",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 853",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 854",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leiria/alcobaca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 855",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 856",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 857",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 858",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 859",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 860",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 861",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 862",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 863",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 864",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 865",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 866",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 867",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 868",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 869",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 870",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 871",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 872",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 873",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 874",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 875",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 876",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 877",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 878",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 879",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 880",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 881",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/benalmadena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 882",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 883",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 884",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 885",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 886",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 887",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 888",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 889",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 890",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 891",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 892",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/felanitx/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 893",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 894",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/muro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 895",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 896",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 897",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 898",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 899",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 900",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 901",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 902",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 903",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 904",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 905",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 906",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 907",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 908",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 909",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 910",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 911",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 912",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 913",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 914",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 915",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 916",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 917",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 918",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 919",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 920",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 921",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 922",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 923",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-a-quatro-maos/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 924",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 925",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 926",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 927",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 928",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 929",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 930",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 931",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 932",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 933",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 934",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 935",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 936",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 937",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 938",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 939",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 940",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 941",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-anti-celulite/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 942",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 943",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 944",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 945",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 946",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 947",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 948",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 949",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 950",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 951",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 952",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 953",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 954",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 955",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 956",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 957",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 958",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 959",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 960",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 961",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 962",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 963",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 964",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 965",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 966",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 967",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 968",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 969",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 970",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 971",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 972",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 973",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 974",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 975",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 976",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 977",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 978",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 979",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 980",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 981",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 982",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 983",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 984",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 985",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 986",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 987",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 988",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 989",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 990",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 991",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 992",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 993",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 994",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 995",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 996",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 997",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 998",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 999",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1000",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1001",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1002",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1003",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1004",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-ayurveda/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1005",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1006",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1007",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1008",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1009",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1010",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1011",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1012",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1013",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1014",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1015",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1016",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1017",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1018",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1019",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1020",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1021",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1022",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1023",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1024",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1025",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1026",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1027",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1028",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1029",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1030",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1031",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1032",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1033",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-circulatoria/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1034",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1035",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1036",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1037",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1038",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1039",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1040",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1041",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1042",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1043",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1044",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1045",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1046",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1047",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1048",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1049",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1050",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1051",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1052",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1053",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1054",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1055",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1056",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1057",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1058",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1059",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1060",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1061",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1062",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1063",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1064",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1065",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-essencias/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1066",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1067",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1068",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1069",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1070",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1071",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1072",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1073",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1074",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1075",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1076",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1077",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1078",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1079",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1080",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1081",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1082",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1083",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1084",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1085",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1086",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1087",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1088",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1089",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1090",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1091",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1092",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1093",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1094",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1095",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1096",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1097",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1098",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1099",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1100",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1101",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1102",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1103",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1104",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1105",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1106",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1107",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1108",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1109",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1110",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1111",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1112",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1113",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1114",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1115",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1116",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1117",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1118",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1119",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1120",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1121",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1122",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-oleo/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1123",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1124",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1125",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1126",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1127",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1128",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1129",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1130",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1131",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1132",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1133",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1134",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1135",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1136",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1137",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1138",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1139",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1140",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1141",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1142",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1143",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1144",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1145",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1146",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1147",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1148",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1149",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1150",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1151",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1152",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1153",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1154",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1155",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1156",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1157",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1158",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1159",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1160",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1161",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1162",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1163",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1164",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1165",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1166",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1167",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1168",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1169",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1170",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1171",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1172",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1173",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1174",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1175",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1176",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1177",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1178",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1179",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1180",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1181",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1182",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1183",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1184",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1185",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1186",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1187",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1188",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1189",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1190",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1191",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1192",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1193",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1194",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1195",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1196",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1197",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1198",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1199",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1200",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1201",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1202",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1203",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1204",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1205",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1206",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1207",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1208",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1209",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1210",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1211",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1212",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1213",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1214",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1215",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1216",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1217",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1218",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1219",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1220",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1221",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1222",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1223",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1224",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1225",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1226",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1227",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1228",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1229",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1230",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1231",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1232",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1233",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1234",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1235",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1236",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1237",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1238",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1239",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1240",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/playa-de-las-americas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1241",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1242",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1243",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1244",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/toledo/carranque/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1245",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1246",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1247",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1248",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1249",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1250",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1251",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1252",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-pedras-quentes/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1253",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1254",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1255",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1256",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1257",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1258",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1259",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1260",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1261",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1262",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1263",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1264",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1265",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1266",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1267",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1268",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1269",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1270",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1271",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1272",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1273",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1274",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1275",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1276",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1277",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1278",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1279",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1280",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1281",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1282",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1283",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1284",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1285",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1286",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1287",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1288",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-com-velas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1289",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1290",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1291",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1292",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1293",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1294",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1295",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1296",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1297",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1298",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1299",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1300",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1301",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1302",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1303",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1304",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1305",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1306",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1307",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1308",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1309",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1310",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1311",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1312",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1313",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1314",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1315",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1316",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1317",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1318",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1319",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1320",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1321",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1322",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1323",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1324",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1325",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1326",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1327",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1328",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1329",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1330",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1331",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1332",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1333",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1334",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1335",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1336",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1337",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1338",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1339",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1340",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1341",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1342",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1343",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1344",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1345",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1346",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1347",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1348",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1349",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-craniofacial/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1350",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1351",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1352",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/a-coruna/carnota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1353",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1354",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1355",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1356",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1357",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1358",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1359",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1360",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1361",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1362",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1363",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1364",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1365",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1366",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1367",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1368",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1369",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1370",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1371",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1372",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1373",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1374",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1375",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1376",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1377",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1378",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/berga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1379",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1380",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1381",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1382",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1383",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1384",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1385",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1386",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1387",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1388",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1389",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1390",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1391",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1392",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1393",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1394",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1395",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1396",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1397",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1398",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1399",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1400",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1401",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1402",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1403",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1404",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1405",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1406",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1407",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1408",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1409",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1410",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1411",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1412",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1413",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1414",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1415",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1416",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1417",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1418",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1419",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/escaldes-engordany/les-escaldes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1420",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1421",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1422",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1423",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1424",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1425",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1426",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/faro/monte-gordo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1427",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1428",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1429",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1430",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1431",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1432",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1433",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1434",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1435",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1436",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/girona/toses/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1437",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1438",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/agaete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1439",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1440",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1441",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1442",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1443",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1444",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1445",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1446",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1447",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1448",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1449",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1450",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1451",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1452",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1453",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1454",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1455",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1456",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1457",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1458",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1459",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1460",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1461",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1462",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1463",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1464",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1465",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1466",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1467",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1468",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leiria/alcobaca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1469",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1470",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1471",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1472",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1473",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1474",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1475",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1476",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1477",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1478",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1479",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1480",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1481",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1482",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1483",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1484",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1485",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1486",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1487",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1488",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1489",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1490",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1491",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1492",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1493",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/benalmadena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1494",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1495",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1496",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1497",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1498",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1499",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1500",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1501",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1502",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/felanitx/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1503",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1504",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/muro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1505",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1506",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1507",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1508",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1509",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1510",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/murcia/archena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1511",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1512",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1513",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1514",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1515",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1516",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1517",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1518",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/paroquia-de-escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1519",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1520",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1521",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1522",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1523",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1524",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1525",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1526",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1527",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1528",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1529",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1530",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1531",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1532",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1533",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1534",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1535",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1536",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1537",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1538",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tarragona/torroja-del-priorat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1539",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1540",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1541",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1542",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1543",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/playa-de-las-americas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1544",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1545",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1546",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1547",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1548",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1549",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1550",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1551",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1552",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1553",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1554",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1555",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1556",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1557",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1558",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1559",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1560",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1561",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1562",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1563",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1564",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-relaxamento/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1565",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1566",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1567",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1568",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1569",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1570",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1571",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1572",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1573",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1574",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1575",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1576",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1577",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1578",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1579",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1580",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1581",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1582",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1583",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1584",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1585",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1586",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1587",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1588",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1589",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1590",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1591",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1592",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1593",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1594",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1595",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1596",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1597",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1598",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1599",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1600",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1601",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1602",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1603",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1604",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1605",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1606",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1607",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1608",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1609",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1610",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1611",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1612",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1613",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1614",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1615",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1616",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1617",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1618",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1619",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1620",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1621",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1622",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1623",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1624",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1625",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-de-tecidos-profundos/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1626",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1627",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1628",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1629",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1630",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1631",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1632",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1633",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1634",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1635",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1636",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1637",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1638",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1639",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1640",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1641",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1642",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1643",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1644",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1645",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1646",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1647",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1648",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1649",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1650",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1651",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1652",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1653",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1654",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1655",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1656",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1657",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1658",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1659",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1660",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1661",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1662",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1663",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1664",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1665",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1666",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1667",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1668",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1669",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1670",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1671",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1672",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1673",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1674",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1675",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1676",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1677",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1678",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1679",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1680",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1681",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1682",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1683",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1684",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1685",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1686",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1687",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1688",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1689",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1690",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1691",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1692",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1693",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1694",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1695",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1696",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1697",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1698",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1699",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1700",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1701",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1702",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1703",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1704",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1705",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1706",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1707",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1708",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1709",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1710",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1711",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1712",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1713",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1714",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1715",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1716",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-descontraturante/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1717",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1718",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1719",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1720",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1721",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1722",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1723",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1724",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1725",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1726",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1727",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1728",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1729",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1730",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1731",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1732",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1733",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1734",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1735",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1736",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1737",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1738",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1739",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1740",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1741",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1742",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1743",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1744",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1745",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1746",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1747",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1748",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1749",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1750",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1751",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1752",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1753",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/mallorca/felanitx/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1754",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1755",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1756",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1757",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1758",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1759",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1760",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1761",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1762",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-desportiva/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1763",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1764",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1765",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1766",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1767",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1768",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1769",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1770",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1771",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1772",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1773",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1774",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1775",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1776",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1777",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1778",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1779",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1780",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1781",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1782",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1783",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1784",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1785",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1786",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1787",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1788",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1789",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1790",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1791",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1792",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1793",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1794",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1795",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1796",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1797",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1798",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1799",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1800",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1801",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1802",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1803",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1804",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1805",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1806",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1807",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1808",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-dos-pes/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1809",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1810",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1811",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1812",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1813",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1814",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1815",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1816",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1817",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1818",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1819",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1820",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1821",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1822",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1823",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1824",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1825",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1826",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1827",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1828",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1829",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1830",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-estimulante/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1831",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1832",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1833",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1834",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1835",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1836",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1837",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1838",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1839",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-exotica/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1840",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1841",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1842",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1843",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1844",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1845",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1846",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1847",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1848",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1849",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1850",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1851",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1852",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1853",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1854",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1855",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1856",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1857",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1858",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1859",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1860",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1861",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1862",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1863",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1864",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1865",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1866",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1867",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1868",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1869",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial-com-mascara/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1870",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1871",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1872",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1873",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1874",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1875",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1876",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1877",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1878",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1879",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1880",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1881",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1882",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1883",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1884",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1885",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1886",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1887",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1888",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1889",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1890",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1891",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1892",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1893",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1894",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1895",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1896",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1897",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1898",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1899",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1900",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1901",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1902",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1903",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1904",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1905",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1906",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1907",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1908",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1909",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1910",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1911",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1912",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1913",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1914",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1915",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1916",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1917",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1918",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1919",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1920",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1921",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1922",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1923",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1924",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-facial/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1925",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1926",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1927",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1928",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1929",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1930",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1931",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1932",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1933",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1934",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1935",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1936",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1937",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1938",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1939",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1940",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1941",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1942",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1943",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1944",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1945",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1946",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1947",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1948",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1949",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1950",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1951",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1952",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1953",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1954",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1955",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1956",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1957",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1958",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1959",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1960",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1961",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1962",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1963",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1964",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1965",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1966",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1967",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-hidratante/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1968",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1969",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1970",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1971",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1972",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1973",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1974",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1975",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1976",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1977",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1978",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1979",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1980",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1981",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1982",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1983",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1984",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1985",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1986",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1987",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1988",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1989",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1990",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1991",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1992",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-holistica/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1993",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1994",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1995",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1996",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1997",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1998",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 1999",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2000",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2001",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2002",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2003",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2004",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2005",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2006",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2007",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2008",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2009",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2010",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2011",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2012",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2013",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2014",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2015",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2016",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2017",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2018",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2019",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2020",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2021",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2022",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2023",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2024",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2025",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2026",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2027",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2028",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2029",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2030",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2031",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2032",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2033",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2034",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2035",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2036",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2037",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/girona/castello-dempuries/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2038",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2039",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2040",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2041",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2042",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2043",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2044",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2045",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/leiria/vestiaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2046",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2047",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2048",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2049",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2050",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2051",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2052",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2053",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2054",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2055",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2056",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2057",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2058",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2059",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2060",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2061",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2062",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2063",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2064",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2065",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2066",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2067",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2068",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2069",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2070",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2071",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2072",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2073",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2074",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2075",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2076",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2077",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2078",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-localizada/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2079",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2080",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2081",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2082",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2083",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2084",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2085",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2086",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2087",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2088",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2089",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2090",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2091",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2092",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2093",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2094",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2095",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2096",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2097",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2098",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2099",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2100",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2101",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2102",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2103",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2104",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2105",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2106",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2107",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2108",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2109",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2110",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2111",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2112",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2113",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2114",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2115",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2116",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2117",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/mallorca/muro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2118",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2119",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2120",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2121",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2122",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2123",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2124",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2125",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2126",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2127",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2128",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2129",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2130",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2131",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-lomi-lomi/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2132",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2133",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2134",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2135",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2136",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2137",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2138",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-mao/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2139",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2140",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2141",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2142",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2143",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2144",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2145",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2146",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2147",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2148",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2149",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2150",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2151",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2152",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2153",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2154",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2155",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2156",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2157",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2158",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2159",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2160",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2161",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2162",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2163",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2164",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2165",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2166",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2167",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2168",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2169",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2170",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2171",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2172",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2173",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2174",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2175",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2176",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2177",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2178",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2179",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2180",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2181",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2182",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2183",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2184",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2185",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2186",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2187",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2188",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2189",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2190",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2191",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2192",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-oriental/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2193",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2194",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2195",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2196",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2197",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2198",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2199",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2200",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2201",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2202",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2203",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2204",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2205",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2206",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/granada/niguelas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2207",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2208",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2209",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2210",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2211",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2212",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2213",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2214",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2215",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2216",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2217",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2218",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2219",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2220",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2221",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2222",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2223",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2224",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-personalizada/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2225",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2226",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2227",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2228",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2229",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2230",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2231",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2232",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2233",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2234",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2235",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2236",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2237",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2238",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2239",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2240",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2241",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2242",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2243",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2244",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2245",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2246",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2247",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2248",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2249",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2250",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2251",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2252",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2253",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2254",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2255",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2256",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2257",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pindas/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2258",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2259",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2260",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2261",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2262",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2263",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2264",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2265",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2266",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2267",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2268",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2269",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2270",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2271",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2272",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2273",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2274",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2275",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2276",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2277",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2278",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2279",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2280",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2281",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2282",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2283",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2284",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2285",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2286",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2287",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2288",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2289",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2290",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2291",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2292",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2293",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2294",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2295",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2296",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2297",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2298",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2299",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2300",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2301",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2302",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2303",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2304",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2305",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2306",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2307",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2308",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2309",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2310",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-pre-natal/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2311",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2312",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2313",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2314",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2315",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2316",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2317",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2318",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2319",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2320",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2321",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2322",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2323",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2324",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2325",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2326",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2327",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2328",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2329",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2330",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2331",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2332",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2333",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2334",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-shiatsu/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2335",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2336",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2337",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/alicante/altea/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2338",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2339",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2340",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2341",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2342",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2343",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/cadiz/novo-sancti-petri/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2344",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2345",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2346",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2347",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2348",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2349",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2350",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2351",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2352",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2353",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2354",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2355",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2356",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2357",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2358",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2359",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-sueca/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2360",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2361",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2362",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2363",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2364",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2365",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2366",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2367",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2368",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2369",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2370",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2371",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2372",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2373",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2374",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2375",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2376",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2377",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2378",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2379",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2380",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2381",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2382",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2383",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2384",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2385",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2386",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-tailandesa/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2387",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2388",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2389",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2390",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2391",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2392",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2393",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2394",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2395",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2396",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2397",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2398",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2399",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2400",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2401",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2402",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2403",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2404",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2405",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2406",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2407",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2408",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2409",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2410",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2411",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/faro/monte-gordo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2412",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2413",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2414",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2415",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2416",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2417",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2418",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2419",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/gran-canaria/san-agustin-maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2420",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2421",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2422",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2423",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2424",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2425",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2426",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2427",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2428",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2429",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2430",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2431",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2432",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2433",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2434",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2435",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2436",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2437",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2438",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2439",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2440",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2441",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2442",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2443",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2444",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2445",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2446",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2447",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2448",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2449",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2450",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2451",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2452",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2453",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2454",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2455",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2456",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2457",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/massagem-terapeutica/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2458",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2459",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/murcia/archena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2460",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2461",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2462",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2463",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2464",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2465",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2466",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2467",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2468",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2469",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2470",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2471",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2472",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2473",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2474",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2475",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2476",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2477",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2478",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2479",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2480",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2481",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2482",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2483",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2484",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2485",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2486",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/caldes-de-montbui/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2487",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2488",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/sant-boi-de-llobregat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2489",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2490",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2491",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2492",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2493",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2494",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2495",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2496",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/grazalema/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2497",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2498",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2499",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2500",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2501",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2502",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2503",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2504",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2505",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2506",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2507",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2508",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2509",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2510",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2511",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2512",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2513",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2514",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2515",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2516",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2517",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2518",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/girona/caldes-de-malavella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2519",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2520",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2521",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2522",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/agaete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2523",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2524",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2525",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2526",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2527",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2528",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2529",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2530",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2531",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/guadalajara/abanades/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2532",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2533",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2534",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2535",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2536",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2537",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2538",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2539",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2540",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2541",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2542",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2543",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/jaen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2544",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/jaen/ubeda/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2545",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2546",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2547",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2548",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2549",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2550",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2551",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2552",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2553",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2554",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2555",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2556",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2557",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2558",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2559",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2560",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2561",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2562",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2563",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2564",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2565",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2566",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2567",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2568",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2569",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2570",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2571",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2572",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2573",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2574",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2575",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2576",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2577",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2578",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2579",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2580",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2581",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2582",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2583",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2584",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2585",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2586",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2587",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2588",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2589",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2590",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2591",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/outras-massagens/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2592",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2593",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/paroquia-de-escaldes-engordany/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2594",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2595",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2596",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2597",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2598",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2599",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2600",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2601",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2602",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2603",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2604",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2605",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2606",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2607",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2608",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2609",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2610",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2611",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2612",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2613",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2614",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2615",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2616",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2617",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2618",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2619",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2620",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2621",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2622",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2623",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2624",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2625",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2626",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2627",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2628",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2629",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2630",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2631",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2632",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2633",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2634",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2635",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2636",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2637",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2638",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2639",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2640",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2641",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lleida/vielha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2642",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2643",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2644",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2645",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2646",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2647",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2648",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2649",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2650",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2651",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2652",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2653",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2654",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2655",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2656",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2657",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2658",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2659",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2660",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2661",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2662",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2663",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2664",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/reflexologia-podal/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2665",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2666",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2667",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2668",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2669",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2670",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/setubal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2671",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/setubal/santiago-do-cacem/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2672",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2673",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2674",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2675",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2676",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2677",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2678",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2679",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2680",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tarragona/torroja-del-priorat/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2681",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2682",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2683",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2684",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2685",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/playa-de-las-americas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2686",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2687",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2688",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2689",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2690",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2691",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2692",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2693",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2694",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2695",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2696",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2697",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2698",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2699",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2700",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2701",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2702",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2703",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2704",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2705",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2706",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2707",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2708",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2709",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2710",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2711",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2712",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2713",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2714",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2715",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2716",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2717",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2718",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2719",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2720",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2721",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2722",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2723",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2724",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2725",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2726",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2727",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2728",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2729",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2730",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2731",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2732",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2733",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2734",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2735",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2736",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2737",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2738",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2739",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2740",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2741",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2742",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2743",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/terapia-do-chocolate/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2744",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2745",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/toledo/carranque/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2746",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2747",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2748",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2749",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2750",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2751",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2752",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2753",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2754",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2755",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2756",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2757",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2758",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2759",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2760",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2761",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/vila-real/ribeira-de-pena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2762",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2763",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/massagens/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2764",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/outros-tratamentos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2765",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2766",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2767",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2768",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2769",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2770",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alava/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2771",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alava/laguardia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2772",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2773",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2774",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2775",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2776",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2777",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2778",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2779",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2780",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2781",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2782",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2783",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2784",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2785",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2786",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2787",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2788",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2789",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2790",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2791",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2792",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2793",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2794",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2795",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2796",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2797",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2798",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2799",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2800",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2801",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2802",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2803",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2804",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2805",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2806",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2807",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2808",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2809",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2810",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2811",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2812",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2813",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2814",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2815",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2816",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2817",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2818",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2819",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2820",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2821",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2822",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2823",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2824",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2825",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2826",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2827",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2828",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2829",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2830",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2831",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2832",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2833",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2834",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2835",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2836",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2837",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2838",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2839",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2840",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2841",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2842",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2843",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2844",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2845",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2846",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2847",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2848",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2849",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2850",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2851",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2852",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2853",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2854",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2855",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2856",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cantabria/medio-cudeyo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2857",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2858",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2859",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2860",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2861",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2862",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2863",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2864",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2865",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2866",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2867",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2868",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2869",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2870",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2871",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2872",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2873",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2874",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2875",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2876",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2877",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2878",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2879",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2880",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2881",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2882",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2883",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2884",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2885",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2886",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2887",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2888",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2889",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2890",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2891",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2892",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2893",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2894",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2895",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2896",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2897",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2898",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2899",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2900",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2901",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2902",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2903",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2904",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2905",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2906",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2907",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2908",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2909",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2910",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2911",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2912",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2913",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2914",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2915",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2916",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2917",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2918",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2919",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2920",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2921",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2922",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2923",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2924",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2925",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2926",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2927",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2928",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2929",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2930",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2931",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2932",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2933",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2934",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2935",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2936",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2937",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2938",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2939",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2940",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2941",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2942",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2943",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2944",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2945",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2946",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2947",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2948",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2949",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2950",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2951",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/envolvimento-corporal/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2952",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2953",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2954",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2955",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2956",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2957",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2958",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2959",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2960",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2961",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2962",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2963",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2964",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2965",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2966",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2967",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2968",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2969",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2970",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2971",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2972",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2973",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2974",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2975",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2976",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2977",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2978",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2979",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2980",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2981",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2982",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2983",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2984",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2985",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2986",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2987",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2988",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2989",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2990",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2991",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lanzarote/teguise/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2992",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2993",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2994",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2995",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2996",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2997",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2998",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 2999",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3000",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3001",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3002",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3003",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3004",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3005",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3006",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3007",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3008",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3009",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3010",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3011",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3012",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3013",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3014",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3015",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3016",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3017",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3018",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3019",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3020",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3021",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3022",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3023",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3024",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3025",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3026",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3027",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3028",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3029",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3030",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3031",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3032",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3033",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3034",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3035",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3036",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3037",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3038",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3039",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3040",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3041",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3042",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3043",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alava/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3044",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alava/laguardia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3045",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3046",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3047",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3048",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3049",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3050",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3051",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3052",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3053",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3054",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3055",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3056",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3057",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3058",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3059",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3060",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3061",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3062",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3063",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3064",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3065",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3066",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3067",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3068",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3069",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3070",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3071",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3072",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3073",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3074",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3075",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3076",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3077",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3078",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3079",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3080",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3081",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3082",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3083",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3084",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3085",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3086",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3087",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3088",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3089",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3090",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3091",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3092",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3093",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3094",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3095",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3096",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3097",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3098",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3099",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3100",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3101",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3102",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3103",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3104",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3105",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3106",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3107",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/mallorca/llucmajor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3108",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3109",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3110",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3111",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3112",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3113",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3114",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3115",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3116",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3117",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3118",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3119",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3120",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3121",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3122",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3123",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3124",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3125",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3126",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3127",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3128",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3129",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3130",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3131",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3132",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3133",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/outros-rituais/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3134",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3135",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3136",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3137",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3138",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3139",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3140",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3141",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3142",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3143",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3144",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3145",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3146",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3147",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3148",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3149",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3150",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3151",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3152",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3153",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3154",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3155",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3156",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3157",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3158",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3159",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3160",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3161",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3162",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3163",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3164",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3165",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/terapia-do-vinho/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3166",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3167",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3168",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3169",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3170",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3171",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3172",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3173",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3174",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3175",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3176",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3177",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-do-contorno-dos-olhos/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3178",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3179",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3180",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3181",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3182",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3183",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3184",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3185",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3186",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3187",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3188",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3189",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3190",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3191",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3192",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3193",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3194",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3195",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3196",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3197",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3198",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3199",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3200",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3201",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3202",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3203",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3204",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3205",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3206",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3207",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3208",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3209",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3210",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3211",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3212",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3213",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3214",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3215",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3216",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3217",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3218",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3219",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3220",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3221",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3222",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3223",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3224",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3225",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3226",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3227",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3228",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3229",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3230",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3231",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3232",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3233",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3234",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3235",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3236",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3237",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3238",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3239",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3240",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3241",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3242",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3243",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3244",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3245",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3246",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3247",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3248",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3249",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3250",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3251",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3252",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3253",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3254",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3255",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3256",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3257",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3258",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3259",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3260",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3261",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3262",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3263",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3264",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3265",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3266",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3267",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3268",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3269",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3270",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3271",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3272",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3273",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3274",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3275",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3276",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3277",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3278",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3279",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3280",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3281",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3282",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3283",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3284",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3285",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3286",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3287",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3288",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3289",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3290",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3291",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3292",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3293",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3294",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3295",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3296",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3297",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3298",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3299",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3300",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3301",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3302",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3303",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3304",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3305",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3306",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3307",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3308",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3309",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3310",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3311",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3312",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3313",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3314",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3315",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3316",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3317",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3318",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3319",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3320",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3321",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3322",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3323",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3324",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3325",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3326",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3327",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3328",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3329",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3330",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3331",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3332",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3333",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3334",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3335",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3336",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3337",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3338",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/tratamento-facial/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3339",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3340",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3341",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3342",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3343",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3344",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3345",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3346",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3347",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/veu-de-colagenio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3348",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/veu-de-colagenio/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3349",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/veu-de-colagenio/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3350",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/veu-de-colagenio/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3351",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3352",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3353",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3354",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/viana-do-castelo/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3355",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3356",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/rituais-de-beleza/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3357",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3358",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3359",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3360",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3361",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3362",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3363",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3364",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3365",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3366",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3367",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3368",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3369",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3370",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3371",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3372",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3373",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3374",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3375",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3376",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3377",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3378",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3379",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3380",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3381",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3382",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3383",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3384",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3385",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3386",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3387",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3388",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3389",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3390",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3391",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3392",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3393",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3394",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3395",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3396",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3397",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3398",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3399",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3400",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3401",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3402",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3403",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3404",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3405",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3406",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3407",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3408",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3409",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3410",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3411",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3412",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3413",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3414",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3415",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3416",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3417",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3418",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3419",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3420",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3421",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3422",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3423",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3424",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3425",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3426",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3427",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3428",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3429",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3430",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3431",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3432",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3433",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3434",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3435",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3436",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3437",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3438",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3439",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3440",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3441",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3442",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3443",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3444",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3445",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3446",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3447",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3448",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3449",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3450",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3451",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3452",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3453",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3454",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3455",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3456",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3457",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3458",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3459",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3460",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3461",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3462",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3463",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3464",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3465",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3466",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3467",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3468",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3469",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3470",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3471",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3472",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3473",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3474",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3475",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3476",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3477",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3478",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3479",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3480",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/aromaterapia/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3481",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3482",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3483",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3484",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3485",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3486",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3487",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3488",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3489",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3490",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3491",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3492",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3493",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3494",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3495",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3496",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3497",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3498",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3499",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3500",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3501",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3502",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3503",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3504",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3505",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3506",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3507",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3508",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3509",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3510",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3511",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3512",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3513",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3514",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3515",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3516",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3517",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3518",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3519",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3520",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3521",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3522",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3523",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3524",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3525",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3526",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/alicante/teulada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3527",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3528",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3529",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3530",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3531",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3532",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3533",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3534",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3535",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3536",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3537",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3538",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3539",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3540",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3541",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3542",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3543",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3544",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3545",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3546",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3547",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3548",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3549",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3550",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3551",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3552",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3553",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3554",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3555",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3556",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/drenagem-linfatica/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3557",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3558",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3559",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3560",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3561",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3562",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3563",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3564",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3565",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3566",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3567",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3568",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3569",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3570",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3571",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3572",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3573",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3574",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3575",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3576",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3577",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3578",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3579",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3580",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3581",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3582",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/monachil/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3583",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3584",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/guadalajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3585",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/guadalajara/brihuega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3586",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3587",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3588",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3589",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3590",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3591",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3592",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3593",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3594",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3595",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3596",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3597",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3598",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3599",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3600",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3601",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3602",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3603",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3604",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3605",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/linda-a-velha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3606",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3607",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3608",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3609",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3610",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lleida/caldes-de-boi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3611",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3612",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3613",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3614",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3615",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3616",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3617",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3618",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3619",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3620",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3621",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3622",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3623",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3624",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3625",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3626",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3627",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3628",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3629",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3630",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3631",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3632",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3633",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3634",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3635",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3636",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3637",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3638",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3639",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3640",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3641",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3642",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3643",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3644",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3645",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3646",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3647",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3648",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3649",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3650",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/benidorm/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3651",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3652",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/l-alfas-del-pi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3653",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3654",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3655",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3656",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3657",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3658",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3659",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3660",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3661",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3662",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3663",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3664",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3665",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/barcelona/vallromanes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3666",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3667",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3668",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3669",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/caceres/caceres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3670",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3671",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3672",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3673",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3674",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3675",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3676",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3677",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cantabria/la-hermida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3678",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3679",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3680",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3681",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3682",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3683",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3684",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3685",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3686",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3687",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3688",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3689",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3690",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3691",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3692",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3693",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3694",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3695",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3696",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3697",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3698",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/faro/monchique/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3699",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3700",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3701",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3702",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3703",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3704",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3705",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3706",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3707",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3708",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3709",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3710",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3711",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3712",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3713",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3714",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3715",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3716",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3717",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3718",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3719",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3720",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3721",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3722",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/huelva/punta-umbria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3723",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3724",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3725",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3726",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3727",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3728",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3729",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3730",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/leon/astorga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3731",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3732",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3733",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3734",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3735",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3736",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3737",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3738",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3739",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madeira/porto-moniz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3740",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madeira/santana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3741",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3742",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3743",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3744",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3745",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3746",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3747",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3748",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3749",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3750",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3751",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3752",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3753",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3754",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3755",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3756",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3757",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3758",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3759",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3760",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3761",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3762",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3763",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3764",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3765",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3766",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3767",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3768",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3769",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3770",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3771",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3772",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3773",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3774",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3775",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3776",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3777",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3778",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3779",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3780",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3781",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3782",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3783",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3784",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3785",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3786",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3787",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3788",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3789",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3790",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/peeling-corporal/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3791",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3792",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pontevedra/moana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3793",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pontevedra/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3794",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3795",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3796",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3797",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3798",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3799",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3800",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/murcia/los-alcazares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3801",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/pressoterapia/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3802",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3803",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3804",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3805",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3806",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3807",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3808",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3809",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3810",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3811",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3812",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3813",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3814",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3815",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3816",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3817",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3818",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3819",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3820",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3821",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3822",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3823",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3824",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3825",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3826",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3827",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3828",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3829",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3830",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3831",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3832",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3833",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3834",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3835",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3836",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3837",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3838",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3839",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3840",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/castellon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3841",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/castellon/peniscola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3842",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3843",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3844",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3845",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3846",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3847",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3848",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3849",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3850",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3851",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3852",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3853",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3854",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3855",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3856",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3857",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3858",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3859",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3860",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3861",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/granada/loja/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3862",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3863",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3864",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3865",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3866",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3867",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3868",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3869",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3870",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3871",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3872",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3873",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3874",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3875",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3876",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3877",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3878",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3879",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3880",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3881",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3882",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3883",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3884",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3885",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3886",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3887",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3888",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3889",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3890",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3891",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3892",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3893",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3894",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3895",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/tenerife/san-cristobal-de-la-laguna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3896",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3897",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3898",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3899",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3900",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3901",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-corporal/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3902",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3903",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3904",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3905",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3906",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3907",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3908",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3909",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3910",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3911",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3912",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3913",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3914",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3915",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3916",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/faro/albufeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3917",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3918",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3919",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3920",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3921",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3922",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3923",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3924",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3925",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3926",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3927",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3928",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3929",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3930",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3931",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3932",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3933",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3934",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3935",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3936",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/tratamento-das-pernas-cansadas/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3937",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3938",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valencia/paterna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3939",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3940",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3941",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3942",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3943",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3944",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3945",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3946",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3947",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/vizcaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3948",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-corporais/vizcaya/urduna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3949",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3950",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3951",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3952",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3953",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3954",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3955",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3956",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3957",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3958",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3959",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3960",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3961",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3962",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3963",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3964",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3965",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3966",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3967",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3968",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3969",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3970",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3971",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3972",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3973",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3974",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3975",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3976",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3977",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3978",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3979",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3980",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3981",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3982",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3983",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3984",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3985",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3986",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3987",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3988",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3989",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3990",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3991",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3992",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3993",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3994",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3995",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3996",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3997",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3998",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 3999",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4000",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4001",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4002",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4003",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4004",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4005",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4006",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4007",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4008",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4009",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4010",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4011",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4012",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4013",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4014",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4015",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4016",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4017",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4018",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4019",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4020",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4021",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4022",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4023",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4024",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4025",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4026",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4027",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4028",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4029",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4030",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4031",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4032",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4033",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4034",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4035",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4036",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4037",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4038",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4039",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4040",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4041",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4042",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4043",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4044",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4045",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4046",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4047",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4048",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4049",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4050",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4051",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4052",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4053",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4054",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4055",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4056",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4057",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4058",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4059",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4060",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4061",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4062",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4063",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4064",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4065",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4066",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4067",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4068",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4069",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4070",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4071",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4072",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4073",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4074",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4075",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4076",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4077",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4078",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4079",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/cadiz/vejer-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4080",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4081",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4082",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4083",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/distrito-de-faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4084",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4085",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/faro/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4086",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/faro/portimao/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4087",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4088",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4089",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4090",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4091",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4092",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4093",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4094",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4095",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4096",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4097",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4098",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4099",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial-com-mascara/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4100",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4101",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4102",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4103",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4104",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4105",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/badajoz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4106",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/badajoz/merida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4107",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4108",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4109",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4110",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4111",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4112",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4113",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4114",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4115",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4116",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/extremadura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4117",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4118",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4119",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4120",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4121",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4122",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4123",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4124",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4125",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4126",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4127",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4128",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4129",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4130",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/lugo/vilalba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4131",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4132",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4133",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4134",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4135",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4136",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4137",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4138",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4139",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4140",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4141",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/massagem-facial/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4142",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4143",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4144",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4145",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4146",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4147",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4148",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4149",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4150",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4151",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4152",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4153",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4154",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4155",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4156",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4157",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4158",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4159",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4160",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4161",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4162",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4163",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4164",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tarragona/montbrio-del-camp/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4165",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4166",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4167",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4168",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4169",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4170",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4171",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4172",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4173",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4174",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4175",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4176",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/a-coruna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4177",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/a-coruna/outes/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4178",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4179",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/acores/ponta-delgada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4180",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4181",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/albacete/albacete/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4182",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4183",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/alicante/alicante/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4184",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/alicante/calpe/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4185",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/alicante/denia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4186",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/alicante/rojales/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4187",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4188",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/almeria/almeria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4189",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/almeria/roquetas-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4190",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/andalucia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4191",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/aragon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4192",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4193",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/asturias/langreo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4194",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/asturias/parres/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4195",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4196",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/barcelona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4197",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/calella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4198",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/la-garriga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4199",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/seva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4200",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/barcelona/sitges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4201",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/burgos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4202",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/burgos/aranda-de-duero/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4203",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4204",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/cadiz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4205",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/chiclana-de-la-frontera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4206",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/rota/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4207",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/tarifa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4208",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cadiz/zahara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4209",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/canarias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4210",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4211",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cantabria/torrelavega/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4212",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/castilla-la-mancha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4213",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/castilla-y-leon/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4214",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cataluna/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4215",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/comunidad-autonoma-de-cantabria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4216",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/comunidad-de-madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4217",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/comunidad-valenciana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4218",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cordoba/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4219",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/cordoba/lucena/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4220",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4221",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4222",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4223",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4224",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4225",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/distrito-de-vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4226",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4227",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/evora/evora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4228",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/fuerteventura/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4229",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/fuerteventura/antigua/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4230",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/fuerteventura/pajara/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4231",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/galicia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4232",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/girona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4233",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/girona/llanars/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4234",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/girona/lloret-de-mar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4235",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/girona/sant-feliu-de-guixols/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4236",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/girona/torrent/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4237",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4238",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/gran-canaria/las-palmas-de-gran-canaria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4239",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/gran-canaria/maspalomas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4240",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/gran-canaria/mogan/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4241",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/gran-canaria/san-bartolome-de-tirajana/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4242",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4243",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/granada/almunecar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4244",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/granada/granada/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4245",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/granada/motril/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4246",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/guipuzcoa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4247",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/guipuzcoa/hondarribia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4248",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/guipuzcoa/orio/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4249",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huelva/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4250",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huelva/ayamonte/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4251",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huelva/cartaya/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4252",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huelva/costa-occidental/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4253",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huelva/isla-cristina/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4254",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4255",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/huesca/huesca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4256",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/islas-baleares/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4257",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lanzarote/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4258",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lanzarote/puerto-del-carmen/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4259",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lanzarote/yaiza/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4260",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/leiria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4261",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/leiria/monte-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4262",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/leiria/nazare/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4263",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4264",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lisboa/ericeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4265",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lisboa/lisboa/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4266",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lisboa/sintra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4267",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lisboa/torres-vedras/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4268",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lleida/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4269",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lleida/vallbona-de-les-monges/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4270",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lugo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4271",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lugo/guitiriz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4272",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/lugo/panton/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4273",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4274",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/madeira/funchal/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4275",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4276",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/madrid/madrid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4277",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4278",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/estepona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4279",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/fuengirola/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4280",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/malaga/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4281",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/marbella/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4282",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/mijas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4283",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/malaga/torremolinos/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4284",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4285",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/cala-millor/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4286",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/calvia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4287",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/colonia-sant-jordi/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4288",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/palma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4289",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/sant-llorenc-des-cardassar/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4290",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/soller/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4291",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/mallorca/son-servera/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4292",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4293",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/murcia/murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4294",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4295",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/oporto/oporto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4296",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/oporto/sao-felix-da-marinha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4297",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/oporto/vila-do-conde/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4298",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/oporto/vila-nova-de-gaia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4299",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/pais-vasco/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4300",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/pontevedra/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4301",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/pontevedra/vigo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4302",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/principado-de-asturias/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4303",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/regiao-de-acores/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4304",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/regiao-de-madeira/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4305",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/region-de-murcia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4306",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/segovia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4307",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/segovia/santo-tome-del-puerto/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4308",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4309",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/sevilla/sevilla/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4310",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/soria/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4311",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/soria/burgo-de-osma-ciudad-de-osma/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4312",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tarragona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4313",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tarragona/el-perello/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4314",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tarragona/salou/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4315",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4316",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/adeje/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4317",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/arona/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4318",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/guia-de-isora/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4319",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/puerto-de-la-cruz/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4320",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/santa-cruz-de-tenerife/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4321",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/tenerife/santa-ursula/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4322",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4323",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/toledo/toledo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4324",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4325",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4326",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4327",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4328",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4329",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4330",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4331",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4332",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4333",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4334",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4335",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/tratamento-facial/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4336",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4337",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valencia/gandia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4338",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valencia/valencia/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4339",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valladolid/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4340",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valladolid/olmedo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4341",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valladolid/penafiel/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4342",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/valladolid/san-bernarndo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4343",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/viana-do-castelo/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4344",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/viana-do-castelo/arcos-de-valdevez/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4345",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/viana-do-castelo/caminha/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4346",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/vila-real/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  },
  {
    name: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.name ?? "Treatment landing: 4347",
    path: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.path ?? "/pt/tratamentos-faciais/vila-real/boticas/",
    props: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.props ?? false,
    meta: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta || {},
    alias: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.alias || [],
    redirect: Treatment_46pageKl1WrB5Nh2DsdjrxROCk0vPfFVSwhniYluoo6QrbemkMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-treatments-afc3c9de-3c05-4d23-b81f-fecdecdfdd8c/b2c-build/core/pages/treatment/Treatment.page.vue")
  }
]